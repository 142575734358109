export const Data = [
  {
    id: 1,
    image: './clients/client1.jpg',
    title: "Regi & Misi - Esküvő",
    description:
      "Köszönjük. ❤ Mindkettőnk családja több soron kiemelte mennyire tetszett nekik a munkád és az elkészült képek. 🙂",
  },
  {
    id: 2,
    image: './clients/client2.jpg',
    title: "Noncsi & Joci - Esküvő",
    description:
      "Nagyon szépen köszönjük, megkaptuk a képeket, szuperek, csak ismételni tudjuk magunkat olyan szépek lettek! 😘",
  },
  {
    id: 3,
    image: './clients/client3.jpg',
    title: "Emőke & Dávid - Esküvő",
    description:
      "Nagyon tetszenek de Nagyon ☺️😊 meg is könnyeztem. Sokan dicsérték már az eddigi munkádat s azt is ahogy az emberekkel bántál s beszéltél.",
  },
  {
    id: 4,
    image: './clients/client4.jpg',
    title: "Gitta - Glamour fotózás",
    description:
      "Erikával pár perc alatt egymásra hangolódtunk, bármilyen képsorokról is legyen szó, minden percben segítőkész volt, egyből értette és érezte, hogy mit szeretnék, tudta milyen póz áll jól, magabiztosságot éreztem a fotózás alatt általa. Egyéni, nőies és családi fotózáson is résztvettem már nála és mindig a vártnál csodásabb képeket kaptam kézhez. 😉",
  },
  {
    id: 5,
    image: './clients/client5.jpg',
    title: "Kriszta & Árpi - Esküvő",
    description:
      "Nagyon-nagyon és nagyon tetszenek a fotók 😍 nagyon köszönjük Neked!!! Pont így szerettük volna, hogy mindenkiről legyenek természetes, felszabadult fotók. Némelyiket még meg is könnyeztem, pl Anyukám az unokáival...szóval bárki keres fotóst, tuti Téged fogunk ajánlani. Nagyon szép nyarat Neked és kis családodnak! Kriszta és Árpi ❤",
  },
  {
    id: 6,
    image: './clients/client6.jpg',
    title: "Szandi & Tamás - Kismama fotózás",
    description:
      "Ohh, de szuper gyors voltál 💕 Csodás képek köszönjük szépen! 🥰😘",
  },
  {
    id: 7,
    image: './clients/client7.jpg',
    title: "Gréta - Párfotózás",
    description:
      "Úú nagyon szépen köszönjük! 🙂 Gyönyörűek lettek! 😍",
  },
  {
    id: 8,
    image: './clients/client8.jpg',
    title: "Zsófi & Dani - Esküvő",
    description:
      "Végre volt egy szusszanásnyi időm, hogy belepillanthassak a képekbe és hát fenomenálisak lettek! Nagyon szépen köszönjük a munkádat És a jövőben, ha majd egyszer aktuális lesz a baba projekt, féltétlen keresni fogunk meg!",
  },
  {
    id: 9,
    image: './clients/client9.jpg',
    title: "Eszter & Dávid - Esküvő",
    description:
      "Nagyon nagyon köszönjük a képeket! ❤️❤️❤️ Annyira gyönyörűek! Köszönjük, hogy visszaadod a csodaszép képek által az esküvő és lagzi élményét, hangulatát! A kreatívos képek is annyira jók! 😍 Nagyon hálásak vagyunk értük, és mindig örömmel fogjuk őket nézegetni!",
  },
  {
    id: 10,
    image: './clients/client10.jpg',
    title: "Zsófi - Jegyesfotózás",
    description:
      "Úristen! Hát ezek eszméletlen jók lettek! (És még ráadásul hihetetlenül gyorsan el is készültél velük 😮 ) Már meg is vannak a személyes kedvenceink. Nagyon nagyon tetszenek, nagyon elégedettek vagyunk velük!",
  },
  {
    id: 11,
    image: './clients/client11.jpg',
    title: "Éva - Családi fotózás",
    description:
      "Nagyon szépen köszönjük a képeket! Sok kedvenc van közte, nagyon szépek lettek! :) Gondolunk majd rád, ha szeretnénk még a közeljövőben profi családi képeket készíttetni. :)",
  },
  {
    id: 12,
    image: './clients/client12.jpg',
    title: "Dia - Újszülött fotózás",
    description:
      "Csodásak lettek, mint mindig😍szavakat se találok, mennyire örülünk nekik, olyan cuki lett rajtuk tényleg, köszönjük 🙏🙏🙏🙏💗💗💗💗",
  },
  {
    id: 13,
    image: './clients/client13.jpg',
    title: "Adri - Gyermekfotózás",
    description:
      "Erikám, hat ezek megint csodásra sikeredtek! Rosszkedvnek es rossz időnek semmi nyoma! Én nem tudom, hogy ezt hogy csinálod. Én ezer képet csinálok a mobilommal, de az összes nem ér annyit, mint a Tieid",
  },
  {
    id: 14,
    image: './clients/client14.jpg',
    title: "Kitti & Norbi - Esküvő",
    description:
      "Már a jegyes fotóknál éreztem, hogy 'nyilvánosan' is szeretnék majd visszajelzést adni, mert már azokat a képeket imádtunk, illetve veled is nagyon jó volt, pedig mindig izgultunk a fotózás előtt, de olyan aranyos voltál meg annyira természetes volt az egész, hogy jó élmény lett! nagyon köszönünk mindent, mindenkinek ajánlani foglak!"
  },
  {
    id: 15,
    image: './clients/client15.jpg',
    title: "Klári - Családi fotózás",
    description:
      "Eri, újra nagyon nagyon köszönjük!! 🙂 ❤️ Annyira jókat röhögtem közben 😃 Csodás képek és főleg a türelmed, hogy ilyen jól elkaptad őket!! 🙂 ❤️"
  },
  {
    id: 16,
    image: './clients/client16.jpg',
    title: "Panna - Portréfotózás",
    description:
      "A képek pedig nagyon tetszenek! Örülök a közös munkának! 😃"
  },
  {
    id: 17,
    image: './clients/client17.jpg',
    title: "Vivien - Családi fotózás",
    description:
      "Sikerült megnéznem a képeket. Nagyon szépek lettek. Végre van rengeteg szuper családi fotónk. 🙂 Nagyon-nagyon köszönjük. ❤️Remélem lesz még alkalmunk a jövőben veled fotóztatni. 🙂"
  },
  {
    id: 18,
    image: './clients/client18.jpg',
    title: "Annamária - Családi fotózás",
    description:
      "Kedves Erika! Csodálatosak a képek, pont ilyen volt az elképzelésen! Nagyon szépen köszönjük!"
  },
  {
    id: 19,
    image: './clients/client19.jpg',
    title: "Dóri & Bernát - Esküvő",
    description:
      "Nagyon szépen köszönjük a képeket, gyönyörűek lettek, fantasztikus munkát végeztél☺😍 Nekünk is nagy öröm volt, hogy Te voltál a fotósunk, Nálad jobbat nem is tudtunk volna választani! 💟"
  },
  {
    id: 20,
    image: './clients/client20.jpg',
    title: "Janka & Gyuri - Esküvő",
    description:
      "Nagyon nagyon csodálatosak a képek😍😍😍😍 már többször végig néztem es annyira joook😍😍😍😍 olyan jó pillanatokat kaptál el😍😍😍😍 nagyon tetszenek, Gyurinak is :) Gyuri olyan büszke volt magára hogy borítókép lett belőle😅"
  },
  {
    id: 21,
    image: './clients/client21.jpg',
    title: "Adrienn - Családi fotózás",
    description:
      "Szia, ennél szebb reggelt 🌞🌻 fel se fogja az agyam, h miket látok, ki se tudja választani, h melyik a legjobb, mert mind szenzációs! Csodás képek, köszönöm szépen 💜"
  },
  {
    id: 22,
    image: './clients/client22.jpg',
    title: "Gabriella - Újszülött fotózás",
    description:
      "Nagyon hálásak vagyunk Erinek (Emlékmorzsák) ezekért a csodálatos fotókért. Köszönjük a napodat és a rengeteg idődet ❤ Eri, a végtelen türelmed, a hihetetlen gyorsaságod és minden beleölt munkád ❤ mindig nagy öröm a fotózás veled ❤ és a képeid is meseszépek. Köszönjük ❤"
  },
  {
    id: 23,
    image: './clients/client23.jpg',
    title: "Adrienn - Családi fotózás",
    description:
      "Erika! Csak ámulunk és bámulunk! 😍 Mesés képek lettek, csudajó pillanatokat kaptál el, nagyon köszönjük a munkádat, remek fotós vagy ám! 🤩 Nagyon hálásak vagyunk, szuper karácsonyi ajándékot adunk így a családnak! 😊 Reméljük, mi is tudjuk valahogy viszonozni mindezt, az biztos, hogy megyünk mi még a te utcádba! 😉"
  },
  {
    id: 24,
    image: './clients/client24.jpg',
    title: "Kinga - Portréfotózás",
    description:
      "Jaj nagyon köszönöm a képeket Uram isten csoda szép képek 😍 El se hiszem h én vagyok rajtuk."
  },
  {
    id: 25,
    image: './clients/client25.jpg',
    title: "Zsófi & Ákos - Esküvő",
    description:
      "Szia Erika! 😊 Nagyon szépen köszönjük a gyönyörű képeket, csodaszép pillanatokat örökítettél meg!"
  },
  {
    id: 26,
    image: './clients/client26.jpg',
    title: "Fanni - Családi fotózás",
    description:
      "Kedves Erika! Köszönjük szépen a képeket, nagyon tetszenek! 🙂"
  },
  {
    id: 27,
    image: './clients/client27.jpg',
    title: "Anita - Családi fotózás",
    description:
      "Nagyon szép fotókat készített a kis családunkról Erika! Nagyon jól éreztük magunkat a fotózas során. Panni kislányunk is imádta. Csak ajánlani tudom őt, ha fotózkodni szeretnétek. Köszönjük szépen még egyszer, Erika!"
  },
  {
    id: 28,
    image: './clients/client28.jpg',
    title: "Brigitta - Ünnepi fotózás",
    description:
      "Csodálatosak a képek. Csak ajánlani tudom. Többször fotózta a családunkat. Imádtuk az összes képet. Köszönjük szépen mégegyszer ❤"
  },
  {
    id: 29,
    image: './clients/client29.jpg',
    title: "Agronity - Üzleti portré",
    description:
      "Arculat fotózáshoz kerestünk fotóst, aki megörökít bennünket, az eredménnyel pedig teljes mértékben elégedettek vagyunk! Erika profi volt, úgy érezzük, többet kihozott belőlünk, mint amit előre reméltünk. :) Maximálisan ajánljuk!"
  },
  {
    id: 30,
    image: './clients/client30.jpg',
    title: "Csilla - Kismama fotózás",
    description:
      "Nagyon szépek! ❤️❤️❤️ Köszönjük szépen, Erika!! 😊"
  },
  {
    id: 31,
    image: './clients/client31.jpg',
    title: "Zoltán - Családi fotózás",
    description:
      "Gyönyörű képek egy kedves művésznőtől! 🙂 Csak ajánlani tudom mindenkinek!"
  },
  {
    id: 32,
    image: './clients/client32.jpg',
    title: "Judit - Kismama fotózás",
    description:
      "Nagyon-nagyon szépen köszönjük a gyors fotózást, a képfeldolgozást, a közvetlenséget és kedvességet!!!!! 😍 -Ja, és tök jók a Barkás fotók, szerintem nyugodt szívvel vágj bele a kutyaportréfotozásba is!"
  },
  {
    id: 33,
    image: './clients/client33.jpg',
    title: "Eszter & Gábor - Újszülött fotózás",
    description:
      "Köszönjük szépen, csodálatos képek lettek! Nagyon-nagyon köszönjük a munkádat, örök emlékek maradnak ezek a képek! 😍😊😊 Csak nézem a képeket, és egyszerűen nem tudok betelni velük. 😊😊 Nagyon köszi tényleg! 😍"
  },
  {
    id: 34,
    image: './clients/client34.jpg',
    title: "Kriszti & Józsi - Keresztelő fotózás",
    description:
      "Szia 😍 Köszönjük a szuper képeket! 🥰"
  },
  {
    id: 35,
    image: './clients/client35.jpg',
    title: "Klári - Újszülött fotózás",
    description:
      "Gyönyörűek a képek nagyon nagyon köszönjük!!! 🙂😍 Annyira jó hogy itt voltál. 😍 Nagyon szép lett mind, nagyon jó hogy megörökítetted, annyira ismételhetetlen pillanatok!! 😍"
  },
  {
    id: 36,
    image: './clients/client36.jpg',
    title: "Melinda - Családi fotózás",
    description:
      "Nagyon köszönjük:-) Szuper képek lettek. Puszi"
  },
  {
    id: 37,
    image: './clients/client37.jpg',
    title: "Judit - Családi fotózás",
    description:
      "Végig néztük a képeket, nagyon szuperek 🙂 Köszönjük szépen ismét 🙂"
  },
  {
    id: 38,
    image: './clients/client38.jpg',
    title: "Bianka - Portréfotózás",
    description:
      "Nagyon szépen köszönöm h ilyen sokat kidolgoztal nekem. Nagyon jók lettek, Annyira örülök. A barátaimnak is nagyon tetszenek, Nagyon hálás vagyok, Köszönöm szépen."
  },
  {
    id: 39,
    image: './clients/client39.jpg',
    title: "Hajnalka - Családi fotózás",
    description:
      "Ahhh, nagyon tetszenek!!!! 🙂 Nagyon köszönjük!!!!! Annyira boldog vagyok hogy Lara olyan a képeken amilyen a hétköznapokban, boldog, huncut kislány. 😉 Keresni fogunk jövőre is, készülj! 😉"
  },
  {
    id: 40,
    image: './clients/client40.jpg',
    title: "Vivi - Családi fotózás",
    description:
      "Bele néztem, nem bírtam ki!! Csodásak lettek! A fiam egy modell! Koszi Erika, hogy az első karácsonyunkat is te örökítetted meg! ❤️"
  },
  {
    id: 41,
    image: './clients/client41.jpg',
    title: "Stefánia - Családi fotózás",
    description:
      "Annyira jók, csak belepörgettem a családosokba és milyen sok kép nagyon köszönjük!❤️ Sikerült végignéznem Annyira jók lettek😍 Imádjuk Ezentúl tuti h csak téged kérünk 😀"
  },
];
