import React, { useState } from 'react'
import './services.css'

const Services = () => {
  const [toggleState, setToggleState] = useState(0);

  const toggleTab = (index) => {
    setToggleState(index);
  }

  return (
    <section className="services section" id="services">
      <h2 className="section__title">Szolgáltatásaim</h2>
      <span className="section__subtitle">Ti vagytok a fókuszban</span>

      <div className="services__container container grid">
        <div className="services__content">
          <>
            <img src={require('../../assets/portrait/portrait18.jpg')} alt="" onClick={() => toggleTab(1)} className="services__img"/>
            <h3 className="services__title" onClick={() => toggleTab(1)}>Lifestyle Portré</h3>
          </>

          <div className={toggleState === 1 ? "services__modal active-modal" : "services__modal"}>
          <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>
            <div className="services__modal-content">
              <h3 className="services__modal-title">Lifestyle Portré</h3>
              <p className="services__modal-description">
                Mióta a fényképezőgépet a kezembe fogtam, mindig az embert szerettem fotózni. Célom, hogy a 
                legszebb, ugyanakkor legtermészetesebb képeket készítsem el rólad. Ehhez én elsősorban a természetes 
                fényeket szeretem használni, a képeket is kültéren vagy napfénystúdióban, esetleg a 
                munkahelyi közegben készítem el.
                </p>
                <p className="services__modal-description">
                Az átadott képeket utómunkával ellátva adom át, online galérián keresztül. Az utómunka magába 
                foglalja a szín- és fénykorrekciót, vágások igazítását, valamint a bőr retusálást természetes mértékig.
                </p>
                <p className="services__modal-description">
                Nyers képek átadása nem lehetséges.
                </p>
                <h4 className="services__modal-description">Időtartam:</h4>
                <p className="services__modal-description">
                1 - 1.5 óra
                </p>
                <h4 className="services__modal-description">Helyszín:</h4>
                <p className="services__modal-description">
                szabadtéren, napfénystúdióban, saját munkahelyen vagy otthonodban
                </p>
                <h4 className="services__modal-description">Ár:</h4>
                <p className="services__modal-description">
                35.000 Ft – 10 db kép<br />
                50.000 Ft – 20 db kép
              </p>
            </div>
          </div>
        </div>

        <div className="services__content">
          <>
            <img src={require('../../assets/wedding/MerciEsPisti/MerciEsPisti8.jpg')} alt="" className="services__img" onClick={() => toggleTab(2)}/>
            <h3 className="services__title" onClick={() => toggleTab(2)}>Esküvői fotózás</h3>
          </>

          <div className={toggleState === 2 ? "services__modal active-modal" : "services__modal"}>
          <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>

            <div className="services__modal-content">

              <h3 className="services__modal-title">Esküvői fotózás</h3>
              <p className="services__modal-description">
              Szenvedélyem megörökíteni a pillanatokat, fürkészni mások szépségét, azokat lencsevégre kapni!
              Az esküvők személyes kedvenceim, jó látni mások boldogságát, életre szólóan rögzíteni a fontos történéseken kívül azokat is, amelyeket a Nagy Napon esetleg nem volt
              alkalmatok észrevenni! Az esküvői galériában a legfőbb eseményeken kívül mindig készítek részletfotókat a
              dekorációrról, helyszínről is.
              </p>
              <h3 className="services__modal-title">Csomagok</h3>
              <p className="services__modal-description">
                Ahány pár, annyi esküvő! Legyen kis családias esküvő, vagy nagy lakodalom, keressetek bizalommal.
                </p>
                <p className="services__modal-description">
                Esküvői fotózást az ország egész területén vállalok.
                </p>
                <p className="services__modal-description">
                Amennyiben tetszenek a portfóliómban található képsorozatok, úgy kérlek írj nekem üzenetet a
                következő információkkal: időpont, helyszín,rendelkezésre állási idő, amin
                szeretnétek ha fotóznék, illetve a násznép hozzávetőleges száma. Az alábbi csomagjaim általában lefedik az esküvőket, de ezen információk tudatában egyedi, személyre szabott árajánlatra is van lehetőség.
              </p>

              <h3 className="services__modal-title">Mini Csomag</h3>
                <h4 className="services__modal-description">Tartalma:</h4>
                <p className="services__modal-description">
                - polgári és/vagy egyházi szertartás
                </p>
                <p className="services__modal-description">
                - csoportképek a vendégekkel
                </p>
                <h4 className="services__modal-description">Rendelkezésre állás:</h4>
                <p className="services__modal-description">
                maximum 2 óra
                </p>
                <h4 className="services__modal-description">Ár:</h4>
                <p className="services__modal-description">
                90.000 Ft
                </p>
                <h4 className="services__modal-description"> Átadott képek száma:</h4>
                <p className="services__modal-description">
                50-100 db
                </p>

                <h3 className="services__modal-title">Mini Csomag Extra</h3>
                <h4 className="services__modal-description">Tartalma:</h4>
                <p className="services__modal-description">
                - polgári és/vagy egyházi szertartás
                </p>
                <p className="services__modal-description">
                - csoportképek a vendégekkel
                </p>
                <p className="services__modal-description">
                - kreatív párfotózás aznap
                </p>
                <h4 className="services__modal-description">Rendelkezésre állás:</h4>
                <p className="services__modal-description">
                maximum 3 óra
                </p>
                <h4 className="services__modal-description">Ár:</h4>
                <p className="services__modal-description">
                150.000 Ft
                </p>
                <h4 className="services__modal-description"> Átadott képek száma:</h4>
                <p className="services__modal-description">
                100-150 db
                </p>

                <h3 className="services__modal-title">Klasszikus Csomag</h3>
                <h4 className="services__modal-description">Tartalma:</h4>
                <p className="services__modal-description">
                - polgári és/vagy egyházi szertartás
                </p>
                <p className="services__modal-description">
                - csoportképek a vendégekkel
                </p>
                <p className="services__modal-description">
                - esküvői vacsora fotózása tortavágás vagy menyecsketánc végéig (max. hajnali 1 óráig)
                </p>
                <p className="services__modal-description">
                - kreatív párfotózás aznap
                </p>
                <p className="services__modal-description">
                - jegyesfotózás 50% árkedvezménnyel
                </p>
                <h4 className="services__modal-description">Rendelkezésre állás:</h4>
                <p className="services__modal-description">
                maximum 10 óra
                </p>
                <h4 className="services__modal-description">Ár:</h4>
                <p className="services__modal-description">
                290.000 Ft
                </p>
                <h4 className="services__modal-description"> Átadott képek száma:</h4>
                <p className="services__modal-description">
                minimum 500 db
                </p>

                <h3 className="services__modal-title">Klasszikus Csomag Extra</h3>
                <h4 className="services__modal-description">Tartalma:</h4>
                <p className="services__modal-description">
                - kikérés / first look
                </p>
                <p className="services__modal-description">
                - polgári és/vagy egyházi szertartás
                </p>
                <p className="services__modal-description">
                - csoportképek a vendégekkel
                </p>
                <p className="services__modal-description">
                - esküvői vacsora fotózása tortavágás vagy menyecsketánc végéig (max. hajnali 1 óráig)
                </p>
                <p className="services__modal-description">
                - kreatív párfotózás aznap vagy egy másik napon
                </p>
                <p className="services__modal-description">
                - jegyesfotózás 50% árkedvezménnyel
                </p>
                <h4 className="services__modal-description">Rendelkezésre állás:</h4>
                <p className="services__modal-description">
                maximum 12 óra
                </p>
                <h4 className="services__modal-description">Ár:</h4>
                <p className="services__modal-description">
                310.000 Ft
                </p>
                <h4 className="services__modal-description"> Átadott képek száma:</h4>
                <p className="services__modal-description">
                minimum 600 db
                </p>

                <h3 className="services__modal-title">Prémium Csomag</h3>
                <h4 className="services__modal-description">Tartalma:</h4>
                <p className="services__modal-description">
                - menyasszony és vőlegény készülődése
                </p>
                <p className="services__modal-description">
                - kikérés / first look
                </p>
                <p className="services__modal-description">
                - polgári és/vagy egyházi szertartás
                </p>
                <p className="services__modal-description">
                - csoportképek a vendégekkel
                </p>
                <p className="services__modal-description">
                - esküvői vacsora fotózása tortavágás vagy menyecsketánc végéig (max. hajnali 1 óráig)
                </p>
                <p className="services__modal-description">
                - kreatív párfotózás aznap vagy egy másik napon
                </p>
                <p className="services__modal-description">
                - ajándék jegyesfotózás
                </p>
                <h4 className="services__modal-description">Rendelkezésre állás:</h4>
                <p className="services__modal-description">
                maximum 14 óra
                </p>
                <h4 className="services__modal-description">Ár:</h4>
                <p className="services__modal-description">
                350.000 Ft
                </p>
                <h4 className="services__modal-description"> Átadott képek száma:</h4>
                <p className="services__modal-description">
                minimum 700 db
                </p>
            </div>
          </div>
        </div>

        <div className="services__content">
          <>
            <img src={require('../../assets/couple/VanesszaEsIsti/VanesszaEsIsti21.jpg')} alt="" className="services__img" onClick={() => toggleTab(3)}/>
            <h3 className="services__title" onClick={() => toggleTab(3)}>Pár- és jegyesfotózás</h3>
          </>

          <div className={toggleState === 3 ? "services__modal active-modal" : "services__modal"}>
          <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>

            <div className="services__modal-content">
              <h3 className="services__modal-title">Jegyesfotózás</h3>
              <p className="services__modal-description">
                Jegyesfotózás alkalmával lehetőségünk nyílik jobban megismerkedni egymással. Emellett előnye,
                hogy szokjátok kicsit, milyen a kamera előtt állni, így megelőzve azt, hogy az esküvőn okozzon ez
                fölösleges izgatottságot, frusztrációt. Úgy képzeljétek el, mint egy kirándulást, vagy randit, csak megy
                még valaki veletek, aki közben kattint párat. A helyszín választásakor is nagyobb a szabadságunk.
              </p>
              <h4 className="services__modal-description">Időtartam:</h4>
              <p className="services__modal-description">
              1 - 2 óra
              </p>
              <h4 className="services__modal-description">Helyszín:</h4>
              <p className="services__modal-description">
              Általatok választott helyszín
              </p>
              <h4 className="services__modal-description">Ár:</h4>
              <p className="services__modal-description">
              60.000 Ft (az ár a stúdió bérleti díját nem tartalmazza).
              </p>
              <h4 className="services__modal-description"> Átadott képek száma:</h4>
              <p className="services__modal-description">
              40-60 db
              </p>
              

              <h3 className="services__modal-title">Kreatív párfotózás</h3>
              <p className="services__modal-description">
                Külön is kérhető párfotózás, vagy a kreatív fotózás az esküvői szettben!
              </p>
              <h4 className="services__modal-description">Időtartam:</h4>
              <p className="services__modal-description">
              1 - 2 óra
              </p>
              <h4 className="services__modal-description">Helyszín:</h4>
              <p className="services__modal-description">
              Általatok választott helyszín
              </p>
              <h4 className="services__modal-description">Ár:</h4>
              <p className="services__modal-description">
              60.000 Ft (az ár a stúdió bérleti díját nem tartalmazza).
              </p>
              <h4 className="services__modal-description"> Átadott képek száma:</h4>
              <p className="services__modal-description">
              40-60 db
              </p>
            </div>
          </div>
        </div>

        <div className="services__content">
          <>
            <img src={require('../../assets//family/family13.jpg')} alt="" className="services__img" onClick={() => toggleTab(4)}/>
            <h3 className="services__title" onClick={() => toggleTab(4)}>Kismama fotózás</h3>
          </>

          <div className={toggleState === 4 ? "services__modal active-modal" : "services__modal"}>
          <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>

            <div className="services__modal-content">
                <h3 className="services__modal-title">Kismamafotózás</h3>
                <p className="services__modal-description">
                 Ez a csomag azoknak szól, ahol még az első gyermek érkezését várják, így a kismamáról készülnek elsősorban portrék, de ha jön a jövendőbeli apuka a fotózásra, úgy közös képeket is készítünk.
                </p>
                <h4 className="services__modal-description">Időtartam:</h4>
                <p className="services__modal-description">
                1 - 1.5 óra
                </p>
                <h4 className="services__modal-description">Helyszín:</h4>
                <p className="services__modal-description">
                szabadtéren, stúdióban vagy saját otthonotokban
                </p>
                <h4 className="services__modal-description">Ár:</h4>
                <p className="services__modal-description">
                40.000 Ft (az ár a stúdió bérleti díját nem tartalmazza!).
                </p>
                <h4 className="services__modal-description"> Átadott képek száma:</h4>
                <p className="services__modal-description">
                35-40 db
                </p>
            </div>
          </div>
        </div>

        <div className="services__content">
          <>
          <img src={require('../../assets/family/family106.jpg')} alt="" className="services__img" onClick={() => toggleTab(5)}/>
            <h3 className="services__title" onClick={() => toggleTab(5)}>Családi fotózás</h3>
          </>

          <div className={toggleState === 5 ? "services__modal active-modal" : "services__modal"}>
          <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>

            <div className="services__modal-content">

              <h3 className="services__modal-title">Családi fotózás</h3>
                <p className="services__modal-description">
                Ez a csomag azoknak szól, akik a gyerekeikkel együtt szeretnének képeket a családi albumba.
                A fotózáson egész családról készülnek képek, külön a testvérekről, portrék a gyerekekről, illetve szeretek készíteni a szülőkről is 1-2 képet, ahol csak ketten vannak.
                </p>
                <h4 className="services__modal-description">Időtartam:</h4>
                <p className="services__modal-description">
                1-1,5 óra (nagycsalád - 4 vagy több gyerek - esetén az időtartam illetve az ár és a kép darabszáma is nő!)
                </p>
                <h4 className="services__modal-description">Helyszín:</h4>
                <p className="services__modal-description">
                szabadtéren, stúdióban vagy saját otthonotokban
                </p>
                <h4 className="services__modal-description">Ár:</h4>
                <p className="services__modal-description">
                45.000 Ft (az ár a stúdió bérleti díját nem tartalmazza!).
                </p>
                <h4 className="services__modal-description"> Átadott képek száma:</h4>
                <p className="services__modal-description">
                50-60 db
                </p>

                <h3 className="services__modal-title">Családi ünnepi fotózás</h3>
                <p className="services__modal-description">
                Családi jeles pillanatok megörökítése sem maradhat ki a repertoárból, így lehetőség van arra is, hogy egy születésnapi zsúr, keresztelő, ballagási családi programon kérjetek fotózást.
                </p>
                <p className="services__modal-description">
                További részletekről akkor tudunk beszélni, ha leírjátok pontosan miről szeretnétek, hogy képeket készítsek, hány óra rendelkezésre állásra van szükségetek!
                </p>
                
                <h3 className="services__modal-title">"Egy napunk" csomag</h3>
                <p className="services__modal-description">
                Hamar elszaladnak az évek, és sokszor a nagy rohanásban nincs idő 1-1 cuki pillanatról jó képet készíteni! Erre találtam ki azt, hogy lehetőséget adok arra, 
                hogy egy egész napon át fotóználak titeket, a reggeli rituálétól egész az esti meseolvasásig!
                </p>
                <h4 className="services__modal-description">Időtartam:</h4>
                <p className="services__modal-description">
                ~10-12 óra
                </p>
                <h4 className="services__modal-description">Helyszín:</h4>
                <p className="services__modal-description">
                saját otthonotok, illetve ahova napközben mentek és elkísérhetlek titeket (pl. játszótér)
                </p>
                <h4 className="services__modal-description">Ár:</h4>
                <p className="services__modal-description">
                180.000 Ft
                </p>
                <h4 className="services__modal-description"> Átadott képek száma:</h4>
                <p className="services__modal-description">
                350-400 db
                </p>
            </div>
          </div>
        </div>

        <div className="services__content">
          <>
          <img src={require('../../assets/family/family69.jpg')} alt="" className="services__img"  onClick={() => toggleTab(6)}/>
            <h3 className="services__title" onClick={() => toggleTab(6)}>Ajándékutalvány</h3>
          </>

          <div className={toggleState === 6 ? "services__modal active-modal" : "services__modal"}>
          <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>

            <div className="services__modal-content">
                <h3 className="services__modal-title">Ajándékutalvány</h3>
                <p className="services__modal-description">
                Lehetőséged van fotózást ajándékozni. Kérlek keress üzenetben, melyik 
                csomagot szeretnéd és kinek a nevére szóljon az utalvány. Az utalvány érvényességi ideje kiállítástól számított 6 hónapig érvényes.
                </p>
                <p className="services__modal-description">
                Kiszállás Szeged 30 km-es körzetében ingyenes, ezen túl 110 Ft/km útiköltséggel kell számolni, amely oda-vissza útra értendő.
                </p>
            </div>
          </div>
        </div>


      </div>
    </section>
  )
}

export default Services
