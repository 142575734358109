import React from 'react'
import { Link } from 'react-router-dom'

const PortfolioItems = ({item}) => {
  let assethPath = require.context('../../assets/', true, /\.(png|jpg|svg)$/);

  return (
    <div className="portfolio__card" key={item.id}>
      <Link
        to= '/pictures'
        state={{ item: item }}
        >
        <img src={assethPath(item.image)} alt="" className="portfolio__img"/>
        <h3 className="portfolio__title">{item.title}</h3>
      </Link>  
    </div>
  )
}

export default PortfolioItems
