import React from 'react';
import './App.css';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import Home from './components/home/Home';
import Portfolio from './components/portfolio/Portfolio';
import Scrollup from './components/scrollup/Scrollup';
import Services from './components/services/Services';
import Testimonial from './components/testimonial/Testimonial';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Pictures from './components/pictures/Pictures';


const App = () => {
  return (
    <Router>
    <Header />
    <main className='main'>
      <Routes>
      <Route exact path='/' element={
        <>
          < Home />
          < Services />
          <Portfolio />
          <Testimonial />
          <Contact />
          <Scrollup />
        </>
      }
      />
      <Route exact path='/pictures' element= {
        <Pictures />
      }/>
      </Routes>
    </main>
    <Footer />
    </Router>
  )
}

export default App;
