export const projectsData = [
  {
    id: 1,
    image: './wedding/MerciEsPisti/MerciEsPisti4.jpg',
    title: "Merci & Pisti - Esküvő",
    location: "Gyula, Gellény Birtok",
    category: "esküvő",
    tag: "./wedding/MerciEsPisti/MerciEsPisti",
    alt: "merci_es_pisti_",
    count: 98
  },
  {
    id: 2,
    image: './couple/BettiEsTomi/BettiEsTomi8.jpg',
    title: "Betti & Tomi - Párfotózás",
    location: "",
    category: "párfotózás",
    tag: "./couple/BettiEsTomi/BettiEsTomi",
    alt: "betti_es_tomi_",
    count: 20
  },
  {
    id: 3,
    image: './wedding/JankaEsGyuri/JankaEsGyuri60.jpg',
    title: "Janka & Gyuri - Esküvő",
    location: "Szentendre, Pap-sziget",
    category: "esküvő",
    tag: "./wedding/JankaEsGyuri/JankaEsGyuri",
    alt: "janka_es_gyuri_",
    count: 83
  },
  {
    id: 4,
    image: './family/family93.jpg',
    title: "Kismama & Család",
    location: "",
    category: "Kismama & Család",
    tag: "./family/family",
    alt: "family_",
    count: 141
  },
  {
    id: 5,
    image: './couple/VanesszaEsIsti/VanesszaEsIsti20.jpg',
    title: "Vanessza & Isti - Párfotózás",
    location: "",
    category: "Párfotózás",
    tag: "./couple/VanesszaEsIsti/VanesszaEsIsti",
    alt: "vanessza_es_isti_",
    count: 25
  },
  {
    id: 6,
    image: './wedding/DavidEsEszter/DavidEsEszter88.jpg',
    title: "Dávid & Eszter - Esküvő",
    location: "Algyő, Kastélykert Fogadó",
    category: "esküvő",
    tag: "./wedding/DavidEsEszter/DavidEsEszter",
    alt: "david_es_eszter_",
    count: 133
  },
  {
    id: 7,
    image: './portrait/portrait19.jpg',
    title: "Lifestyle Portré",
    location: "",
    category: "Lifestyle Portré",
    tag: "./portrait/portrait",
    alt: "portrait_",
    count: 32
  },
  {
    id: 8,
    image: './wedding/DoriEsBernat/DoriEsBernat85.jpg',
    title: "Dóri & Bernát - Esküvő",
    location: "Szegedi Havas Boldogasszony Templom",
    category: "esküvő",
    tag: "./wedding/DoriEsBernat/DoriEsBernat",
    alt: "dori_es_bernat_",
    count: 85
  },
  {
    id: 9,
    image: './couple/DoriEsKristof/DoriEsKristof16.jpg',
    title: "Dóri & Kristóf - Jegyesfotózás",
    location: "",
    category: "párfotózás",
    tag: "./couple/DoriEsKristof/DoriEsKristof",
    alt: "dori_es_kristof_",
    count: 21
  },
  {
    id: 10,
    image: './wedding/ZsofiEsDani/ZsofiEsDani14.jpg',
    title: "Zsófi & Dani - Esküvő",
    location: "Zsombó, Rózsa Sándor Csárda",
    category: "esküvő",
    tag: "./wedding/ZsofiEsDani/ZsofiEsDani",
    alt: "zsofi_es_dani_",
    count: 66
  },
  {
    id: 11,
    image: './couple/EszterEsAti/EszterEsAti9.jpg',
    title: "Eszter & Ati - Jegyesfotózás",
    location: "",
    category: "párfotózás",
    tag: "./couple/EszterEsAti/EszterEsAti",
    alt: "eszter_es_ati_",
    count: 24
  },
  {
    id: 12,
    image: './wedding/LiziEsPeti/LiziEsPeti45.jpg',
    title: "Lizi & Peti - Esküvő",
    location: "Mórahalom, Pótkerék Csárda",
    category: "esküvő",
    tag: "./wedding/LiziEsPeti/LiziEsPeti",
    alt: "lizi_es_peti_",
    count: 109
  },
  {
    id: 13,
    image: './couple/KittiEsNorbi/KittiEsNorbi13.jpg',
    title: "Kitti & Norbi - Jegyesfotózás",
    location: "",
    category: "párfotózás",
    tag: "./couple/KittiEsNorbi/KittiEsNorbi",
    alt: "kitti_es_norbi_",
    count: 19
  },
  {
    id: 14,
    image: './wedding/OrsiEsDani/OrsiEsDani57.jpg',
    title: "Orsi & Dani - Esküvő",
    location: "Kerekegyháza, Kunsági Major",
    category: "esküvő",
    tag: "./wedding/OrsiEsDani/OrsiEsDani",
    alt: "orsi_es_dani_",
    count: 65
  },
  {
    id: 15,
    image: './couple/DoriEsBernat/DoriEsBernat12.jpg',
    title: "Dóri & Bernát - Jegyesfotózás",
    location: "",
    category: "párfotózás",
    tag: "./couple/DoriEsBernat/DoriEsBernat",
    alt: "dori_es_bernat_",
    count: 20
  },
  {
    id: 16,
    image: './couple/GretiEsMartin/GretiEsMartin3.jpg',
    title: "Gréti & Martin - Jegyesfotózás",
    location: "",
    category: "párfotózás",
    tag: "./couple/GretiEsMartin/GretiEsMartin",
    alt: "greti_es_martin",
    count: 28
  },
  {
    id: 17,
    image: './couple/EszterEsDavid/EszterEsDavid7.jpg',
    title: "Eszter & Dávid - Jegyesfotózás",
    location: "",
    category: "párfotózás",
    tag: "./couple/EszterEsDavid/EszterEsDavid",
    alt: "eszter_es_david_",
    count: 19
  },
  {
    id: 18,
    image: './couple/MerciEsPisti/MerciEsPisti19.jpg',
    title: "Merci & Pisti - Jegyesfotózás",
    location: "",
    category: "párfotózás",
    tag: "./couple/MerciEsPisti/MerciEsPisti",
    alt: "merci_es_pisti_",
    count: 29
  },
];

export const projectsNav = [
  {
    name: "Összes"
  },
  {
    name: "Esküvő"
  },
  {
    name: "Párfotózás"
  },
  {
    name: "Kismama & Család"
  },
  {
    name: "Lifestyle Portré"
  }
];