import React from 'react'
import Send from '../icons/Send'
import "./home.css"

const Data = () => {
  return (
    <div className="home__data">
      <h1 className="home__title">Vági Erika</h1>
      <h3 className="home__subtitle">fotográfus</h3>
      <p className="home__description-first">
        Üdvözöllek a honlapomon és köszönöm, hogy megtisztelsz azzal, hogy az oldalamat látogatod!
      </p>
      <p className="home__description">
      Magamat lifestyle stílusú fotósként definiálom, ami annyit takar, hogy szeretem, ha életszagú képeket rögzíthetek a modelleimről, az erőltetett pózok, műmosolyok helyett a valós érzelmeit és pillanatait igyekszem megörökíteni a pároknak, családoknak.
      </p>
    <a href="#contact" className="button button--flex">
      Üzenj nekem
      <Send />
    </a>
    </div>
  )
}

export default Data
