import React, { useState, useEffect, useCallback } from 'react'
import { useLocation } from 'react-router'
import './pictures.css'

import CloseIcon from '@mui/icons-material/Close';
import LazyLoad from 'react-lazyload';
import PrevIcon from '@mui/icons-material/ArrowBack';
import NextIcon from '@mui/icons-material/ArrowForward';

import { projectsData } from '../portfolio/Data';

const Pictures = () => {
  const location = useLocation();

  const { item }  = location.state ? location.state : {item : projectsData[0]};
  let assethPath = require.context('../../assets/', true, /\.(png|jpg|svg)$/);

  const imageList = []
  for (let index = 1; index <= item.count; index++) {
    imageList.push({imgSrc : assethPath(item.tag+index+".jpg"), alt: item.alt+index, title: item.title, location: item.location, id: index })
  }

  const [model, setModel] = useState(true);
  const [tempImgSrc, setTempImgSrc] = useState(imageList[0].imgSrc);
  const [prevIndex, setPrevIndex] = useState(imageList.length - 1);
  const [nextIndex, setNextIndex] = useState(1);

  const getImg =  useCallback((imgSrc, index) => {
    setTempImgSrc(imgSrc);
    setPrevIndex(index === 0 ? imageList.length - 1: index - 1);
    setNextIndex(index === imageList.length - 1 ? 0 : index + 1);
    setModel(true);
  }, [imageList.length]);

  const handlePaging = useCallback((event) => {
    if (event.keyCode === 37) {
      getImg(imageList[prevIndex].imgSrc, prevIndex);
    }
    else if (event.keyCode === 39) {
      getImg(imageList[nextIndex].imgSrc, nextIndex);

    }
    else if (event.keyCode === 27) {
      setModel(false);
    }
  }, [getImg, imageList, nextIndex, prevIndex])

  useEffect(() => {
    window.scrollTo(0, 0);
    // Bind the event listener
    document.addEventListener("keydown", handlePaging);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("keydown", handlePaging);
    };
    
  }, [handlePaging]);

  return (
    <section className="pictures section" id="pictures">
      <h3 className="section__title">{imageList[0].title}</h3>
      <span className="section__subtitle">{imageList[0].location}</span>
      <div className={model? "model open" : "model"}>
        <LazyLoad><img src={tempImgSrc} alt='modalImage' className="imgModal"/></LazyLoad>
        <CloseIcon onClick={() => setModel(false) } className="exitIcon"/>
        <PrevIcon onClick={() => getImg(imageList[prevIndex].imgSrc, prevIndex) } className="prevIcon"/>
        <NextIcon onClick={() => getImg(imageList[nextIndex].imgSrc, nextIndex) } className="nextIcon"/>
      </div>
      <div className="gallery">
        {imageList.map((item, index) => {
          return (
            <div role="none" className="pics" key={index} onClick={() => getImg(item.imgSrc, item.id - 1)} onKeyDown={handlePaging}>
                <LazyLoad height={0} once offset={1000}><img src={item.imgSrc} alt={item.alt} className="customImg" /></LazyLoad>
            </div>
          )
        })}
      </div>
    </section>

  )
}

export default Pictures


