import React from 'react'
import "./footer.css"

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container container">
        <h1 className="footer__title">
          Emlékmorzsák
        </h1>
        <ul className="footer__list">
          <li>
            <a href="/#services" className="footer__link">Szolgáltatásaim</a>
          </li>
          <li>
            <a href="/#portfolio" className="footer__link">Porfólióm</a>
          </li>
          <li>
            <a href="/#testimonials" className="footer__link">Vélemények</a>
          </li>
        </ul>

        <div className="footer__social">
          <a href="https://www.instagram.com/emlekmorzsak" className="footer__social-link" target='_blank' rel="noopener noreferrer">
            <i className="bx bxl-instagram"></i>
          </a>
          <a href="https://www.facebook.com/emlekmorzsak" className="footer__social-link" target='_blank' rel="noopener noreferrer">
            <i className="bx bxl-facebook"></i>
          </a>
        </div>
        <span className="footer__copy">© Emlékmorzsák - 2023</span>
      </div>
    </footer>
  )
}

export default Footer
