import React from 'react'
import "./testimonial.css";
import { Data } from "./Data";

/* Import Swiper React components */
import { Swiper, SwiperSlide } from "swiper/react";

/* Import Swiper styles */
import "swiper/css";
import "swiper/css/pagination";

/* Import required modules */
import { Pagination } from "swiper";

const Testimonial = () => {
  let assethPath = require.context('../../assets/', true, /\.(png|jpg|svg)$/);

  return (
    <section className="testimonial container section" id="testimonials">
      <h2 className="section__title">Rólam mondták</h2>
      <span className="section__subtitle">Visszajelzések</span>

      <Swiper
        className="testimonial__container"
        loop={true}
        grabCursor={true}
        spaceBetween={24}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          576: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 48
          }
        }}
        modules={[Pagination]}
      >
        {Data.map(({id, image, title, description}) => {

          return (
            <SwiperSlide className="testimonial__card" key={id}>
              <img src= {assethPath(image)} alt="" className="testimonial__img" />
              <h3 className="testimonial__name">{title}</h3>
              <p className="testimonial__description">{description}</p>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </section>

  )
}

export default Testimonial
