import React from 'react'
import Scroll from '../icons/Scroll'

const ScrollDown = () => {
  return (
    <div className="home__scroll">
      <a href="/#services" className="home__scroll-button button--flex">
        <Scroll />
        <span className="home__scroll-name">Görgess le</span>
        <i className="uil uil-arrow-down home__scroll-arrow"></i>
      </a>
    </div>
  )
}

export default ScrollDown
