import React from 'react'
import MyWed from "../icons/MyWed"

const Social = () => {
  return (
    <div className="home__social">
      <a href="https://www.instagram.com/emlekmorzsak" className="home__social-icon" target='_blank' rel="noopener noreferrer">
      <i className="uil uil-instagram"></i>
      </a>

      <a href="https://www.facebook.com/emlekmorzsak" className="home__social-icon" target='_blank' rel="noopener noreferrer">        
      <i className="uil uil-facebook-f"></i>
      </a>

      <a href="https://mywed.com/hu/photographer/emlekmorzsak/" className="home__social-icon" target="_blank" rel="noopener noreferrer">
        <MyWed />
      </a>
    </div>
      
  )
}

export default Social
