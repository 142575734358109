
import React, { useState } from 'react'
import './Header.css'
import Logo from '../icons/LogoGold';
import { Link } from 'react-router-dom'

const Header = () => {
  /*=============== Change background Header ===============*/
  window.addEventListener("scroll", function() {
    const header = document.querySelector(".header");
    if (this.scrollY >= 80) header.classList.add("scroll-header");
    else header.classList.remove("show-header");
  })

  /*=============== Toggle Menu ===============*/
  const [Toggle, showMenu] = useState(false);
  const [activeNav, setActiveNav] = useState("#home");

  return (
    <header className="header">
      <nav className="nav container">
        <Link to='/'>
          <Logo className="nav__logo"/>
        </Link>

        <div className={Toggle ? "nav__menu show-menu" : "nav__menu"}>
          <ul className="nav__list grid">
            <li className="nav__item">
              <a href="/#home" onClick={() => { setActiveNav('#home'); showMenu(!Toggle);} } className={activeNav === "#home" ?
                "nav__link active-link" : "nav__link"}>
                <i className="uil uil-estate nav__icon"></i> Főoldal
              </a>
            </li>

            <li className="nav__item">
            <a href="/#services" onClick={() => { setActiveNav('#services'); showMenu(!Toggle);} } className={activeNav === "#services" ?
                "nav__link active-link" : "nav__link"}>
                <i className="uil uil-camera nav__icon"></i> Szolgáltatásaim
              </a>
            </li>

            <li className="nav__item">
              <a href="/#portfolio" onClick={() => { setActiveNav('#portfolio'); showMenu(!Toggle);}} className={activeNav === "#portfolio" ?
                "nav__link active-link" : "nav__link"}>
                <i className="uil uil-images nav__icon"></i> Portfólióm
              </a>
            </li>

            <li className="nav__item">
              <a href="/#contact" onClick={() => {setActiveNav('#contact'); showMenu(!Toggle); }} className={activeNav === "#contact" ?
                "nav__link active-link" : "nav__link"}>
                <i className="uil uil-message nav__icon"></i> Kapcsolat
              </a>
            </li>
          </ul>

          <i className="uil uil-times nav__close" onClick={() => showMenu(!Toggle)}></i>
        </div>
        <div className="nav__toggle" onClick={() => showMenu(!Toggle)}>
          <i className="uil uil-bars"></i>
        </div>
      </nav>
    </header>
  )
}

export default Header
