import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Send from '../icons/Send';
import './contact.css';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('emlekmorzsak_gmail', 'template_0k9z7yw', form.current, 'user_wINsYe79PFFDA8mtKmtp4')
    e.target.reset()
  };

  return (
    <section className="contact section" id="contact">
      <h2 className="section__title">Kapcsolat</h2>
      <span className="section__subtitle">Ajánlatkérés</span>

      <div className="contact__container container grid">
        <div className="contact__content">
          <h3 className="contact__title">Elérhetőségeim</h3>
          <div className="contact__info">
            <div className="contact__card">
              <i className="bx bx-mail-send contact__card-icon"></i>
              <h3 className="contact__card-title">Email</h3>
              <span className="contact__card-data">info@emlekmorzsak.hu</span>

              <a href="mailto:info@emlekmorzsak.hu" className="contact__button">
                Írj nekem
                <i className="bx bx-right-arrow-alt contact__button-icon"></i>
              </a>
            </div>

            <div className="contact__card">
              <i className="bx bx-phone-call contact__card-icon"></i>
              <h3 className="contact__card-title">Telefonszám</h3>
              <span className="contact__card-data">+36-20-281-59-03</span>
            </div>

            <div className="contact__card">
              <i className="bx bxl-messenger contact__card-icon"></i>
              <h3 className="contact__card-title">Messenger</h3>
              <span className="contact__card-data">emlekmorzsak</span>

              <a href="https://m.me/emlekmorzsak" className="contact__button">Írj nekem <i className="bx bx-right-arrow-alt contact__button-icon"></i></a>
            </div>
          </div>
        </div>


        <div className="contact__content">
          <h3 className="contact__title">Küldj egy üzenetet számomra</h3>

          <form ref={form} onSubmit={sendEmail} className="form contact__form">
            <div className="contact__form-div">
              <label className="contact__form-tag">Név</label>
              <input type="text" name="name" className="contact__form-input" placeholder="Név" />
            </div>

            <div className="contact__form-div">
              <label className="contact__form-tag">Email</label>
              <input type="email" name="email" className="contact__form-input" placeholder="Email" />
            </div>

            <div className="contact__form-div contact__form-area">
              <label className="contact__form-tag">Üzenet</label>
              <textarea name="message" cols="30" rows="10" className="contact__form-input" placeholder="Üzenet" />
            </div>
            <button className="button button--flex">
              Küldés
              <Send />
            </button>
          </form>
        </div>
      </div>
    </section>
  )
}

export default Contact
